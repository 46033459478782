 import firebase from 'firebase';
 // Initialize Firebase
 var config = {
    apiKey: "AIzaSyC9YayNoHRZpJT4zU5-ck_1jqlKSwVdiX4",
    authDomain: "jennurate.firebaseapp.com",
    databaseURL: "https://jennurate.firebaseio.com",
    projectId: "jennurate",
    storageBucket: "jennurate.appspot.com",
    messagingSenderId: "1004561523887"
  };
  firebase.initializeApp(config);